















import { Component, Watch } from 'vue-property-decorator';
import { NavigationMenuItemInterface } from '@/interfaces/NavigationMenuItem.interface';
import { NavigationMenuItems } from '@/misc/NavigationMenuItems';
import { ROUTE_HOME, ROUTE_LOGIN, ROUTE_STORE } from '@/router/routes';
import { namespace } from 'vuex-class';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';

@Component({
  components: {
    NavigationDrawerComponent: () => import(
      /* webpackChunkName: "NavigationDrawerComponent" */
      '@/components/NavigationDrawer.component.vue'
    )
  }
})
export default class HomeView extends mixins(BaseMixin) {
  public navigationMenuItems: NavigationMenuItemInterface[] = [];

  created() {
    // Set up navigation items: if current user is not an admin, only show non-admin-pages.
    let navigationMenuItems = NavigationMenuItems;
    if (!this.currentUser.isAdmin) {
      navigationMenuItems = navigationMenuItems.filter(item => !item.adminOnly);
    }
    this.navigationMenuItems = navigationMenuItems;

    // Redirect to first entry in navbar:
    if (this.$route.path == `/${ROUTE_HOME}`) {
      if (this.currentStore) {
        this.$router.push({ name: navigationMenuItems[0].route, params: { id: this.currentStore.id! } });
      } else {
        this.$router.push({ name: navigationMenuItems[0].route });
      }
    }
  }

  /**
   * Returns all navigation items including children.
   */
  get allNavigationItems(): NavigationMenuItemInterface[] {
    const items: NavigationMenuItemInterface[] = [];
    this.navigationMenuItems.forEach(item => {
      items.push(item);
      item.children?.forEach(child => {
        items.push(child);
      });
    });
    return items;
  }

  /**
   * Route change handler
   */
  @Watch('$route', { immediate: true })
  private onRouteChange() {
    this.$nextTick(() => {
      const items: NavigationMenuItemInterface[] = this.allNavigationItems;
      items.forEach(item => {
        item.isActive = false;
      });
      const activeItem = items.find(item => item.route === this.$route.name);
      if (activeItem != undefined) {
        activeItem.isActive = true;
      }
    });
  }

  /**
   * On Logout handler
   */
  private async onLogout() {
    this.$router.push({ name: ROUTE_LOGIN });
    // Reset each store module:
    Object
      // @ts-ignore
      .keys(this.$store._modulesNamespaceMap)
      .forEach(key => {
          this.$store.commit(`${key}CLEAR_STORE`);
      });
  }
}
